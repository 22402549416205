import { PAGE } from '~/constants/router'

export const HOME_PROMOTION = [
  {
    id: '1',
    image: '/assets/images/components/desktop/home/promotion/bg-promotion-1.png',
    link: PAGE.PROMOTION + '/thuong-nap-200percent',
    title: '200%',
    text1: 'Thưởng nạp lần hai',
    text2: 'Dành cho thành viên mới',
    icon: '/assets/images/components/desktop/home/promotion/icon-promotion-1.png'
  },
  {
    id: '2',
    image: '/assets/images/components/desktop/home/promotion/bg-promotion-2.png',
    link: PAGE.PROMOTION + '/thuong-ve-cuoc-thua-dau-tien-trong-ngay',
    title: '0.2%',
    text1: 'Thưởng Vé Cược',
    text2: 'Thua Đầu Tiên Trong Ngày',
    icon: '/assets/images/components/desktop/home/promotion/icon-promotion-2.png'
  },
  {
    id: '3',
    image: '/assets/images/components/desktop/home/promotion/bg-promotion-3.png',
    link: PAGE.PROMOTION + '/thuong-300percent-cho-lan-nap-dau',
    title: '300%',
    text1: 'Thưởng Nạp 300%',
    text2: 'Cho Phiếu Nạp Đầu Tiên',
    icon: '/assets/images/components/desktop/home/promotion/icon-promotion-3.png'
  },
  {
    id: '4',
    image: '/assets/images/components/desktop/home/promotion/bg-promotion-4.png',
    link: PAGE.PROMOTION + '/thuong-nap-150percent',
    title: '150%',
    text1: 'Cấp vốn khởi nghiệp',
    text2: 'Có ngay 10,000,000VND',
    icon: '/assets/images/components/desktop/home/promotion/icon-promotion-4.png'
  }
]

export const TYPE_NAVIGATE_LINK = 'navigate-link'
export const PLAN_TYPE_SUKIEN_THUONG18 = 3
export const PLAN_TYPE_SUKIEN_HOANTRA15 = 1
export const PLAN_TYPE_SUKIEN_THUONG118 = 2
export const PLAN_TYPE_SUKIEN_THUONG150 = 5
export const PLAN_TYPE_SUKIEN_THUONG100 = 100
